'use client'

import { useCallback } from 'react'
import { createCredentials, removeKeysFromSearchParams } from '@/lib/auth'
import useCheckLoginStatus from '@/hooks/auth/useCheckLoginStatus'
import useHandleProfileResult from '@/hooks/auth/useHandleProfileResult'
import { credentialsSchema } from '@/schemas/auth'
import useIsSignedIn from '@/hooks/auth/useIsSignedIn'
import { SEARCH_PARAM_KEYS } from '@/constants/auth'

const useCheckAuth = () => {
  const [isSignedIn, setIsSignedIn] = useIsSignedIn()

  const checkLoginStatus = useCheckLoginStatus()
  const handleProfileResult = useHandleProfileResult(setIsSignedIn)

  const checkAuth = useCallback(async () => {
    const qs = new URLSearchParams(location.search)
    const qsHtcToken = qs.get(SEARCH_PARAM_KEYS.HTC_TOKEN)

    try {
      await checkLoginStatus(qs)
      const credentials = createCredentials(qs)

      if (credentialsSchema.safeParse(credentials).success) {
        await handleProfileResult(credentials, qsHtcToken)
      }
    } catch (error) {
      console.error('Error while checking auth: ', error)
      // TODO: handle error
      // goErrorPage()
    } finally {
      removeKeysFromSearchParams(Object.values(SEARCH_PARAM_KEYS))
    }
  }, [checkLoginStatus, handleProfileResult])

  return {
    isSignedIn,
    setIsSignedIn,
    checkAuth,
  }
}

export default useCheckAuth
