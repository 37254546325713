import configs from '@/configs'
import { DEFAULT_VRAM_KB } from '@/constants/device'
import { DeviceType } from '@/types/configs'

const getDeviceToVram = () => {
  return typeof window === 'undefined'
    ? globalThis.appConfigFromCache?.features?.device_to_vram
    : configs.appConfig?.features?.device_to_vram
}

export const getTotalVramKb = (deviceType: DeviceType) => {
  const deviceToVram = getDeviceToVram() || []
  const totalVramKb =
    deviceToVram?.find((device) => device.device === deviceType)?.top_vram_kb || DEFAULT_VRAM_KB

  return totalVramKb
}
